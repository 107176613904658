.info-container {
    width: 100%;
    height: 300px;
    background-color: white;
    color: black;
    margin-bottom: 100vh;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media only screen and (max-width: 960px) {
    .info-container {
        height: 200px;
        width: 100%;
    }

    .info > h1 {
        text-align: center;
        font-size: 22px;
    }
}