.cover-container {
    position: relative;
    height: 100vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
    margin-left: 0;
}

.img {
    width: 90%;
    height: 80%;
    opacity: 0.5;
    filter: alpha(opacity=0.5);
    border-radius: 150px;
    margin-left: 0;
}

.cover-container > h1 {
    color: white;
    position: absolute;
    top: 44%;
    font-size: 80px;
    font-family: "Bebas Neue";
    margin-top: 0;
    animation: typing1 4s steps(38) 1s 1 normal both, flash linear 2.7s 4s infinite;
    /*Efecto Máquina de Escribir*/
    overflow: hidden;
    white-space: nowrap;
}

@keyframes typing1 {
    from {
      width: 0;
    }
    to {
      width: 25%;
    }
  }
 
@keyframes flash {
    0% { opacity: 0; } 
    80% { opacity: 1; color:#d22214; } 
    83% { opacity: 0; color:#e0645b; } 
    86% { opacity: 1; color:#fff;}	
    89% { opacity: 0} 
    92% { opacity: 1; color:#d22214;} 
    95% { opacity: 0; color:#e0645b;}
    100% { opacity: 1; color:#fff;}
}

.cover-container > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
}

.enlace {
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid #18bdec;
    animation: typing2 4s steps(38) 1s 1 normal both, 
    blink2 1s steps(1) infinite;
    margin-top: 20%;
}

@keyframes typing2 {
    from {
      width: 0;
    }
    to {
      width: 32%;
    }
  }
  @keyframes blink2 {
    50% {
      border-color: transparent;
    }
  }

.enlace > a {
    margin-top: 8px;
    color: white;
    font-size: 22px;
    text-decoration:none;
    font-family: "Bebas Neue";
    transition: background-color .5s;
    border-radius: 5px;
}

.a:visited {
    color: #504f91;
  }

a:hover {
    border-bottom: 1px solid;
    background: black;
}

.active {
    color: rgb(155, 85, 85);
}

.logo {
    position: absolute;
    left: 14%;
    top: 10%;
    width: 400px;
    height: 200px;
}

.bienvenida {
    position: absolute;
    top: 56%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 50px;
    font-family: "Dosis";
    color: antiquewhite;
    border-right: 0.15em solid #18bdec;
    animation: typing 4s steps(38) 1s 1 normal both, 
    blink 1s steps(1) infinite;
}
  
@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 50%;
    }
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

@media only screen and (min-width: 1500px) {
    .logo {
        margin-left: 20%;
        width: 550px;
        height: 300px;
    }

    .bienvenida {
        font-size: 60px;
    }

    .cover-container > h1 {
        font-size: 90px;
        }

    @keyframes typing1 {
        from {width: 0;}
        to {width: 19%;}
    }

    @keyframes typing2 {
        from {width: 0;}
        to {width: 23%;}
    }

    @keyframes typing {
        from {width: 0;}
        to {width: 42%;}
    }
}

@media screen and (max-width: 960px) {
    .cover-container > h1 {
        top: 36%;
        font-size: 60px;
        text-align: center;
    }

    .bienvenida {
        top: 46%;
        font-size: 24px;
        }

    .logo {
        margin-top: 10%;
        margin-left: 0%;
        width: 290px;
        height: 130px;
    }

    .enlace {
        text-align: center;
    }

    .enlace > a {
        color: white;
        font-size: 15px;
    }

    @keyframes typing1 {
        from {width: 0;}
        to {width: 76%;}
    }

    @keyframes typing2 {
        from {width: 0;}
        to {width: 85%;}
    }

    @keyframes typing {
        from {width: 0;}
        to {width: 90%;}
    }
}
/*.logo {
    text-align: center;
}*/

/*.title {
    margin: 0em 50% 1em 12%;
}*/



