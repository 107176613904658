.navbar {
    position: fixed;
    top: 0;
    /*background-color: transparent;*/
    width: 100%;
    height: 70px;
    padding-left: 25px;
    font-size: 20px;
    z-index: 1;
}

.scrolling {
    background-color: black;
    transition: all 0.5s ease;
}

.navbar-logo {
    height: 60px;
    line-height: 70px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.navbar-logo:hover{
    color: white;
    text-decoration: none;
}
