.palabra {
    margin-top: 7%;
    position: relative;
    align-items: center;
  justify-content: center;
  text-align: center;

}

.card {
    margin-left: 10%;
}

.card2 {
    visibility:hidden
}

.img-fluid {
    width: 65rem;
    margin-left: 0;
    border: 5px solid; 
    color: aqua;
}

.card-img-top {
    width: 50rem;
    margin-left: 0;
    border: 5px solid; 
    color: aqua;
}

@media only screen and (max-width: 960px) {
    .card {
        visibility:hidden
    }

    .card2-img-top {
        width: 22rem;
        margin: 1px 3px;
        border: 5px solid; 
        color: aqua;
    }

    .card2 {
        visibility:visible;
        position: absolute;
        top: 3%;
        height: 100px;
        width: 100%;
    }

    .titulo {
        margin-top: 20%;
        font-family: 'Kaushan Script', cursive;

    }

}