.formulario {
    display: flex;
    margin-top: 7%;
    align-items: center;
    justify-content: center;
}

.valid-user1 {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    margin-left: 30%;
    margin-top: 15%;
    margin-right: 25%;
}

.valid-user1 > div {
    width: 90%;
}

.cuenta{
    float: left;
}

.registrate{
    float:left;
}

.text-center2 a{
    color: rgb(62, 53, 189);
    text-decoration: none;
    cursor: pointer;
}

img, svg {
    vertical-align: middle;
    margin-left: 20%;
}

.text-center2 a:hover{
    background-color: white;
    text-decoration: none;
}

@media only screen and (max-width: 960px) {
    .alert {
        margin-top: 20%;
    }
}


